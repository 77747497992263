<div class="direccion-wrapper" *ngIf="userDates">
    
    <div class="header">
        <h2>Confirma o establece tu direccion de envio para poder recibir tu decimo en tu casa comodamente.</h2>
    </div>

    <form class="form-direccion" (submit)="comprar()">
        <div class="body-form-direccion">
            <input class="form-comtrol-direccion" type="text" name="direccion" [(ngModel)]="userDates.direccion" value="" placeholder="Dirección"/>
            <!--<input class="form-comtrol-direccion" type="text" name="piso" placeholder="Piso, Puerta, Bloque ...."/>-->
            <input class="form-comtrol-direccion" type="text" name="codigo_postal" [(ngModel)]="userDates.codigo_postal" placeholder="Código Postal"/>
            <input class="form-comtrol-direccion" type="text" name="ciudad" [(ngModel)]="userDates.ciudad" placeholder="Ciudad"/>
            <input class="form-comtrol-direccion" type="text" name="provincia" [(ngModel)]="userDates.provincia" value="" placeholder="Provincia"/>
            <input class="form-comtrol-direccion" type="text" name="telefono" [(ngModel)]="userDates.telefono" value="" placeholder="Teléfono"/>
        </div>
		
		<div class="spinner-wrapper" *ngIf="sale">
			<app-spinner forceTo='component-sized' ></app-spinner>
		</div>

        <div class="footer-form-direccion">
            <div class="divider">
                <hr>
            </div>
            <input class="btn btn-confirmacion" [ngClass]="{'btn-disabled': sale}" [disabled]="sale" type="submit" value="Confirmar Compra"/>
        </div>
    </form>
</div>