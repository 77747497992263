import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GamesApiservice } from '@api/services/games/games-api.service';
import { EGamesId } from '@enums/common/games.enum';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BetService } from '@modules/games/bet.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ILottery } from 'src/app/interfaces/plays/play-bet.interface';
import { PlayLottery } from '../play-lottery';

@Component({
	selector: 'app-automatic-play-lottery',
	templateUrl: './automatic-play-lottery.component.html',
	styleUrls: ['./automatic-play-lottery.component.scss']
})
export class AutomaticPlayLotteryComponent extends PlayLottery implements OnDestroy, OnInit {

	public iconStar = faStar;
	public iconCross = faTimes;
	public totalBets: number;
	public reintegro: number;
	public gameNumbers: ILottery[] = [];
	public selectedNumbers: ILottery[] = [];
	public lastNumber: ILottery;
	public loading: boolean;
	public numTickets: number;
	public url: string;
	public lottery = [];
	public date: string;
	public activeNumbers: number = 0;
	public dateObservableSuscription: Subscription;
	public manual = false;
	public desactivaAutomatico = false;

	private randomNumbers = [];
	private lastIndex: number = 0;

	@Input() gameId: EGamesId;

	constructor(

		private betService: BetService,
		private toastrService: ToastrService,
		private gamesApiService: GamesApiservice,

	) {

		super();
		this.options = this.betService.options;
		this.totalBets = 0;
		this.bets = [];
		this.lottery = [];
		this.betService.desactivaAutomaticoModeObservable$.subscribe((res: any) => { this.desactivaAutomatico = res; });
		this.desactivaAutomatico = this.betService.desactivaAutomaticoMode;
		this.betService.manualModeObservable$.subscribe((res: any) => { this.manual = res; });
		this.betService.observablenumberBets.subscribe((res: any) => { this.numTickets = res; });

	}

	ngOnInit() {
		this.activeNumbers = 0;
		this.loading = true;

		this.dateObservableSuscription = this.betService.dateObservable$.subscribe((date: string) => {
			this.date = date;
			this.gamesApiService.getNumbersLotteryDay(this.gameId, this.date)
			this.gamesApiService.numbersLotteryDayObservable$.subscribe( res => {
					this.gameNumbers = res;
					this.loadLottery();
					if (this.lastIndex > 0)
						this.refresh()
				},
				err => {
					console.log(err)
				}
			)

			this.gamesApiService.getNumberLotteryByDate(this.betService.date, this.gameId)
			this.gamesApiService.numbersLotteryByDateObservable$.subscribe(res => { 
				this.randomNumbers = res.data.numeros;
				this.sumarDecimo(null);
			});
		});

	}

	public getUrlTicket(listados: any) {
		//sacar la url del dia indicado

		if (listados[0] && listados[0].miniaturas[1])
			this.url = "http://www.loteriaelnegrito.com/uploads/" + listados[0].miniaturas[1].path + listados[0].miniaturas[1].nombre;
		else
			this.url = "/assets/images/icons/defaultTicket.svg";
	}

	protected clearAutoBet(idx: number): void {

		this.bets.splice(idx, 1);
		let i = 0;
		this.bets.forEach(bet => {
			bet.id = i;
			bet.label = i + 1;
			i++;
		});

		this.betService.setBets(this.bets);
		if (this.bets.length == 0) {

			this.reintegro = -1;

		}
	}

	private loadLottery(): void {

		for (let i = 0; i < this.gameNumbers.length; i++) {
			const a: ILottery = {
				id: i,
				numero: this.gameNumbers[i].numero,
				cantidad: this.gameNumbers[i].cantidad,
				cantidad_compra: 0
			};
			if (a.cantidad > 0) {
				this.activeNumbers++;
			}
			this.lottery.push(a)
		}
		this.loading = false;
	}

	public restarDecimo(l: any): void {
		if (this.numTickets != 0) {
			this.selectedNumbers.pop();
			this.numTickets--;
			this.lastNumber = this.selectedNumbers[this.selectedNumbers.length - 1];
			this.betService.setBets(this.selectedNumbers);
			if (this.betService.bets.length > 1)
				this.betService.setMultiPlayBetLottery(true)
			else
				this.betService.setMultiPlayBetLottery(false)
		}
	}

	public sumarDecimo(l: any): void {
		//sacar con rand un numero entre 0 y el numero de billetes con cantidad > 0
		if (this.numTickets < 12) {
			var num = this.randomNumbers.pop();
			this.lastNumber = num;
			this.numTickets++;

			let aux: ILottery = {
				id: 0,
				numero: num,
				cantidad: 1,
				cantidad_compra: 1,
			}

			this.selectedNumbers.push(aux);
			this.betService.setBets(this.selectedNumbers);
			if (this.betService.bets.length > 1)
				this.betService.setMultiPlayBetLottery(true)
			else
				this.betService.setMultiPlayBetLottery(false)

		}
		else {
			alert("No se pueden comprar automaticamente mas de 12 décimos");
		}
	}

	/**
	 * Get random index
	 */
	randomIndexLottery(re = false): number {
		if (this.lastIndex == 0 || re) {
			var num = Math.floor(Math.random() * this.activeNumbers);
			while (this.lottery[num].cantidad <= 0) {
				num = Math.floor(Math.random() * this.activeNumbers);
			}
			this.lastIndex = num;
			return num;
		}
		else
			return this.lastIndex;
	}

	refresh() {
		var num = this.randomIndexLottery(true);
		var sel = this.selectedNumbers;
		this.selectedNumbers = [];
		this.betService.clearData();

		sel.forEach(() => {
			this.numTickets++;
			this.lottery[num].cantidad--;
			this.lottery[num].cantidad_compra++;
			this.selectedNumbers.push(this.lottery[num]);
		})

		this.betService.setBets(this.selectedNumbers);
	}

	ngOnDestroy() {
		if (this.dateObservableSuscription)
			this.dateObservableSuscription.unsubscribe();
		if (this.betService.sendMode && this.betService.sendMode.id == 1)
			this.betService.clearData()
	}
}
